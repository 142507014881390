import { Validation } from "constants/Validation";
import * as Yup from "yup";

const FeaturedInNewsletterSchema =
    Yup.object().shape({
        featuredInNewsletterId: Yup.string(),
        name: Yup.string().required(Validation.required.message.replaceAll("^", "Name"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
        category: Yup.string(),
        message: Yup.string().matches(Validation.plaintext.regex, Validation.plaintext.message),
    });

export default FeaturedInNewsletterSchema;