import React, { useState } from 'react'
import {
    Grid,
    Paper,
    Button,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { Form, Formik } from 'formik';
import { ContactPersonModel } from 'model/screensModels/membershipRegistration/ContactPersonModel';
import ContactPersonSchema from 'schemas/membershipForm/ContactPersonSchema';
import { UploadFileService } from 'services/shared/UploadFileService';


const MembershipContactPerson = ({ memberlist, setMemberList }) => {

    const [initialValues, setInitialValues] = useState(new ContactPersonModel())

    const addMember = async(values, setFieldValue, setFieldTouched) => {
        const data = await createData(values.name, values.position, values.phoneNumber, values.email, values.contactPersonImage, values.contactIsrepresentative);
        //values.contactPerson.push(data)
        setMemberList(oldArray => [...oldArray, data])
        setFieldValue('name', '');
        setFieldTouched('name', false);
        setFieldValue('position', '');
        setFieldTouched('position', false);
        setFieldValue('phoneNumber', '');
        setFieldTouched('phoneNumber', false);
        setFieldValue('email', '');
        setFieldTouched('email', false);
        setFieldValue('contactPersonImage', '');
        setFieldTouched('contactPersonImage', false);
        setFieldValue('contactIsrepresentative', false);
        setFieldTouched('contactIsrepresentative', false);

    }


    const createData = async(name, position, phoneNumber, email, contactPersonImage, contactIsrepresentative )=> {
         //to get image url
         if (contactPersonImage) {
            const formData = new FormData();
            formData.append("file", contactPersonImage);
            const imageurlData = await UploadFileService.getImagePath(formData);
            contactPersonImage = imageurlData.data.fileName;
        } else {
            contactPersonImage = '';
        }

        return { name, position, phoneNumber, email, contactPersonImage, contactIsrepresentative };
    }

    const removeMember = (index) => {
        setMemberList([
            ...memberlist.slice(0, index),
            ...memberlist.slice(index + 1)
        ]);
    }


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={ContactPersonSchema}
                onSubmit={async (values, { resetForm }) => {
                }}
            >
                {({ handleSubmit, isValid, dirty, values, setFieldValue, setFieldTouched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Typography variant="h4" align="center" marginBottom="30px" >
                            Contact Person
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="name"
                                    label="Name"
                                    placeholder="Enter Your Name"
                                    requiredField
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="position"
                                    label="Position / Designation"
                                    placeholder="Enter Your Position / Designation"
                                    requiredField
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="phoneNumber"
                                    label="Phone Number"
                                    placeholder="Enter Your Contact Number"
                                    requiredField
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="email"
                                    label="Email Id"
                                    placeholder="Enter Your Email Id"
                                    requiredField
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="uploadImage"
                                    name="contactPersonImage"
                                    label="Contact Person Image"
                                // placeholder="Enter Your Email Id"
                                // requiredField
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    className="radiobutton"
                                    control="radiogroup"
                                    label="Is Representative"
                                    name="contactIsrepresentative"
                                    options={[
                                        { key: "Yes", value: true },
                                        { key: "No", value: false },
                                    ]}
                                    requiredField
                                    row
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={(e) => addMember(values, setFieldValue, setFieldTouched)}
                                    disabled={!dirty || !(isValid)}
                                >Add Member</Button>
                            </Grid>

                            {memberlist?.length > 0 ?
                                <Grid item xs={12} sm={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">Position</TableCell>
                                                    <TableCell align="right">Phone Number</TableCell>
                                                    <TableCell align="right">Email</TableCell>
                                                    <TableCell align="right">Action</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {memberlist.map((member, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{member.name}</TableCell>
                                                        <TableCell align="right">{member.position}</TableCell>
                                                        <TableCell align="right">{member.phoneNumber}</TableCell>
                                                        <TableCell align="right">{member.email}</TableCell>
                                                        <TableCell align="right"><DeleteIcon onClick={() => { removeMember(index) }} color="action" /></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                : ""}
                        </Grid>
                    </Form>
                )}
            </Formik>


        </>
    )
}

export default MembershipContactPerson
