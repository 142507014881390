export class FeaturedInNewsletter {
    featuredInNewsletterId: string;
    name: string;
    category: string;
    message: string;

    constructor() {
        this.featuredInNewsletterId="";
        this.name="";
        this.category="";
        this.message="";
    }
}