import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
const Header = () => {
    return (
        <div>
            <div className="page-wrapper">
                <header className="main-header">
                    <nav className="main-menu">
                        <div className="main-menu__wrapper">
                            <div className="container">
                                <div className="main-menu__wrapper-inner">
                                    <div className="main-menu__left">
                                        <div className="main-menu__logo">
                                            <a href="/"><img src="images/resources/logo-1wn.png" alt=""></img>
                                            </a>
                                        </div>
                                        <div className="main-menu__main-menu-box">
                                            <a href="#" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                                            <ul className="main-menu__list">
                                                <li>
                                                    {/* <a href="index.html">Home </a> */}
                                                    {/* <Link to="/" activeClassName="active-link">Home</Link> */}
                                                    <NavLink to="/" activeClassName="active-link">Home</NavLink>
                                                </li>
                                                <li>
                                                    {/* <a href="/about-us" activeClassName="active-link">About</a> */}
                                                    <NavLink to="/about-us" activeClassName="active-link">About</NavLink>
                                                    {/* <ul>
                                                        <li><a href="mission.html">Our Mission</a></li>
                                                        <li><Link to="/our-mission">Our Mission</Link></li>
                                                        <li><a href="#">Our Vision</a></li>
                                                    </ul> */}
                                                </li>
                                                <li>
                                                    {/* <a href="#">Verticals</a> */}
                                                    {/* <Link to="/verticals" activeClassName="active-link">Verticals</Link> */}
                                                    <NavLink to="/verticals" activeClassName="active-link">Verticals</NavLink>
                                                </li>
                                                <li className="dropdown">
                                                    {/* <a href="/news-and-media">News & Media</a> */}
                                                    <NavLink to="/news-and-media" activeClassName="active-link">News & Media</NavLink>
                                                    <ul>
                                                        {/* <li><a href="mission.html">Our Mission</a></li> */}
                                                        <li><Link to="#">Affiliations & Accreditations</Link></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    {/* <a href="membership-benefits.html">Membership Benefits</a> */}
                                                    {/* <Link to="/membership-benifits" activeClassName="active-link">Membership Benefits</Link> */}
                                                    <NavLink to="/membership-benifits" activeClassName="active-link">Membership Benefits</NavLink>
                                                </li>
                                                <li>
                                                    {/* <a href="/blog">Blogs</a> */}
                                                    <NavLink to="/blog" activeClassName="active-link">Blogs</NavLink>
                                                </li>
                                                <li>
                                                    {/* <a href="#">Contact Us</a> */}
                                                    {/* <Link to="/contact" activeClassName="active-link">Contact Us</Link> */}
                                                    <NavLink to="/contact" activeClassName="active-link">Contact Us</NavLink>
                                                </li>
                                                <li className="dropdown">
                                                    <a href='javascript:void(0)'>Event & Conferences</a>
                                                    <ul>
                                                        {/* <li><a href="mission.html">Our Mission</a></li> */}
                                                        <li><Link to="csr">CSR</Link></li>
                                                        {/* <li><a href="/conferences">1WN Conferences</a></li> */}
                                                        <li><NavLink to="/conferences" activeClassName="active-link">1WN Conferences</NavLink></li>
                                                        {/* <li><a href="/post-event-gallery">Post Event Gallery</a></li> */}
                                                        <li><NavLink to="/post-event-gallery" activeClassName="active-link">Post Event Gallery</NavLink></li>
                                                        <li><Link to="/event-and-conference">Event & Conferemces Registearion</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="main-menu__right">
                                        <div className="main-menu__search-nav-sidebar-btn-box">
                                            <div className="main-menu__search-box">
                                                <a href="#" className="main-menu__search search-toggler fas fa-search"></a>
                                            </div>
                                            <div className="main-menu__nav-sidebar-icon">
                                            </div>
                                            <div className="main-menu__btn-box">
                                                <a href="#" className="thm-btn main-menu__btn">Login<span></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default Header
