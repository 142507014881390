import { Link } from 'react-router-dom';
import Header from '../../sharedScreen/Header'
import Footer from 'screens/sharedScreen/Footer';
import MembershipForm from './MembershipForm';

const MembershipBenifits = () => {
    return (
        <div>
            {/* <div className="page-wrapper"> */}

            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg membersship-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Membership Benefits</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Benefits</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}


            {/* // <!--About One Start--> */}
            <section className="about-one about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                    </div>
                                    <h2 className="section-title__title">Unlock Global Success with One World Network Membership
                                    </h2>
                                </div>
                                <p className="about-one__text">Joining One World Network is your gateway to global business
                                    growth. Our collaborative platform not only forges powerful partnerships and unlocks new
                                    opportunities but also ensures members collectively promote a shared brand, providing a
                                    competitive edge against multinational giants. Beyond seamless logistics, our network
                                    offers financial protection, expert support in business growth, cutting-edge digital
                                    tools, and customizable marketing services, tailored to your specific needs. We
                                    prioritize member satisfaction through collaborative partnerships, Seamless customer
                                    service, and exclusive global reach. Our members-only quoting platform streamlines
                                    pricing solutions, while authorized logo usage fosters credibility and unity within our
                                    community. Elevate your business with One World Network, where your success is our
                                    commitment.</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right wow fadeInRight" data-wow-delay="300ms" />
                            <div className="about-one__img-box">
                                <div className="about-one__img-1">
                                    <img src="images/resources/benefit-img-1.png" alt="" />
                                </div>
                                <div className="about-one__img-2">
                                    <img src="images/resources/benefit-img-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* <!--About One End--> */}

            {/* ft-service-section Start */}
            <section id="ft-service-3" className="ft-service-section-3">
                <div className="container">
                    <div className="ft-section-title-3 headline text-center wow fadeInUp" data-wow-delay="0ms"
                        data-wow-duration="1500ms">
                        <span className="text-uppercase">We prioritize member satisfaction through collaborative
                            partnerships</span>
                        <h2>Key Features</h2>
                    </div>
                    <div className="ft-service-content-3">
                        <div className="ft-service-content-items-3">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-2" data-wow-delay="0ms" data-wow-duration="1500ms"
                                >
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/benefit-key-1.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/expert.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Global Experts</h3>
                                                <p>Gain unparalleled access to insights and guidance from our esteemed Board
                                                    of Advisors, composed of influential figures and thought leaders in the
                                                    logistics domain. Their expertise spans various facets of the industry,
                                                    providing invaluable strategic direction and industry intelligence.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-3" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/limited-membership.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/members.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Limited Membership</h3>
                                                <p>Emphasizing exclusivity and strategic alignment, our membership
                                                    allocation follows meticulous criteria based on EXIM Statistics. This
                                                    approach ensures that each market segment is represented optimally,
                                                    facilitating focused partnerships tailored to specific regional needs
                                                    and opportunities.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-4" data-wow-delay="400ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/meeting.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/meeting-room.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Personalized Meetings</h3>
                                                <p>Forge meaningful connections and collaborations through regular
                                                    one-on-one meetings with fellow network members. These personalized
                                                    interactions foster deeper relationships, facilitate knowledge sharing,
                                                    and catalyse mutually beneficial business opportunities tailored to
                                                    individual member objectives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-4" data-wow-delay="400ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/security.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/cyber-security.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Financial Security</h3>
                                                <p>Mitigate risks and instil confidence in business transactions with our
                                                    robust Financial Protection Program. Designed to safeguard against
                                                    unforeseen challenges, this initiative provides members with the
                                                    assurance of financial stability and integrity within our network,
                                                    fostering trust and reliability in all dealings.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-4" data-wow-delay="400ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/tool.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/web-design.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Customized Tools</h3>
                                                <p>Empower your business with cutting-edge marketing and PR digital tools
                                                    tailored to the unique needs of logistics operations. From targeted
                                                    promotional campaigns to streamlined communication channels, our suite
                                                    of digital solutions enhances operational efficiency, amplifies brand
                                                    visibility, and drives growth in today's competitive landscape.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/patners.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/agreement.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Trusted Partners</h3>
                                                <p>Collaborate seamlessly with a global network of well-established and
                                                    track-proven freight forwarding partners. Our stringent partner
                                                    selection process ensures reliability, efficiency, and adherence to
                                                    industry best practices, enabling members to deliver exceptional
                                                    logistics solutions with confidence and consistency.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-3" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/easy-qustion.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/question-mark.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Easy Quotation</h3>
                                                <p>Simplify the quoting process and streamline pricing solutions with our
                                                    dynamic and user-friendly quotation exchange platform. Designed for ease
                                                    of use and efficiency, this platform facilitates seamless communication
                                                    between members, enhancing transparency, accuracy, and speed in quoting
                                                    and procurement processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-5" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/worldwide.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/international-delivery.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Worldwide Connectivity</h3>
                                                <p>Expand your global reach and tap into international opportunities through
                                                    partnerships with reliable logistics providers worldwide. Our extensive
                                                    network of trusted partners enables members to access diverse markets,
                                                    forge strategic alliances, and capitalize on emerging trends, driving
                                                    growth and competitiveness on a global scale.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-5" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/solution.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/solutions.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Comprehensive Solutions</h3>
                                                <p>Access a comprehensive suite of advisory services through our
                                                    single-window platform. From regulatory compliance to supply chain
                                                    optimization, our team of industry experts provides tailored guidance
                                                    and solutions to address the unique challenges and opportunities faced
                                                    by logistics businesses, empowering members to navigate complexities
                                                    with confidence and clarity.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-5" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/support.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/business-people.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>SME Support</h3>
                                                <p>Receive dedicated support and resources tailored to the needs of small
                                                    and medium enterprise businesses. From access to funding opportunities
                                                    to specialized training programs, our network is committed to fostering
                                                    the growth and success of SMEs within the logistics industry, ensuring
                                                    equal opportunities for all members to thrive and succeed.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-3" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/business.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/customer-care.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Professional Business Support</h3>
                                                <p>Leverage our network's expertise and resources to drive professional
                                                    development and business growth. From mentorship programs to
                                                    skill-building workshops, we provide members with the tools, insights,
                                                    and connections needed to navigate industry challenges, seize
                                                    opportunities, and achieve their business objectives with excellence and
                                                    efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-3" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/management.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/accountant.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Management</h3>
                                                <p>Our network is led by a team of seasoned professionals with a deep
                                                    understanding of the logistics landscape. Committed to driving growth
                                                    and success for all members, our management team brings together diverse
                                                    expertise, strategic vision, and proactive leadership to steer the
                                                    network towards continual innovation, excellence, and value creation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp ft-service-content-items-3" data-wow-delay="200ms"
                                    data-wow-duration="1500ms">
                                    <div className="ft-service-innerbox-3 position-relative">
                                        <div className="ft-service-img position-relative">
                                            <img src="images/project/conference.png" alt="" />
                                        </div>
                                        <div className="ft-service-text-icon position-relative">
                                            <div
                                                className="ft-service-icon d-flex align-items-center justify-content-center position-absolute">
                                                <img src="images/feture-icon/increase.png" alt="" />
                                            </div>
                                            <div className="ft-service-text position-relative headline pera-content">
                                                <h3>Annual Conference</h3>
                                                <p>Engage and collaborate with industry peers at our annual conference, a
                                                    premier event showcasing the latest trends, innovations, and
                                                    opportunities in the logistics sector. From networking sessions to
                                                    expert panel discussions, the conference provides members with a
                                                    platform to share insights, forge partnerships, and explore new avenues
                                                    for growth and collaboration on a global scale.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ft-service-section End */}

            {/* Begin::Membership Form  */}
            <MembershipForm />
            {/* End::Membership Form  */}

            {/* <!--Why Are We Start--> */}
            <section className="why-are-we padding-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="why-are-we__left">
                                <div className="why-are-we__img">
                                    <img src="images/resources/global.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="why-are-we__right">
                                <p className="counter-one__right-text">1WN-One World One Network stands at the forefront of the
                                    logistics networking industry, offering a transformative platform for businesses to
                                    thrive and succeed in today's interconnected world. With a focus on collaboration,
                                    innovation, and excellence, we empower our members with the resources, connections, and
                                    support needed to navigate challenges, seize opportunities, and achieve their business
                                    goals with confidence and success. Join us and unlock the power of global collaboration
                                    with 1WN-One World One Network.
                                </p>
                                <div className="why-are-we__img-2">
                                    <img src="images/resources/why-we-img-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Why Are We End--> */}
            {/* </div> */}
        </div>





    )
}

export default MembershipBenifits