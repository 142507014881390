
import FormikControl from 'components/wrapperComponents/FormikControl';
import { msgTypes } from 'constants/msgTypes';
import { Form, Formik } from 'formik';
import { ContactEnquiryModel } from 'model/screensModels/contactEnquiry/ContactEnquiryModel';
import React, { useState } from 'react'
import ContactEnquirySchema from 'schemas/contactEnquiry/ContactEnquirySchema';
import { ContactEnquiryService } from 'services/menu/contactEnquiry/ContactEnquiryService';

const ContactEnquiry = () => {

    const [initialValue, setInitialValue] = useState(new ContactEnquiryModel());

    const categoryList = [
        { key: "Membership Related Queries", value: "Membership Related Queries" },
        { key: "Network Related Queries", value: "Network Related Queries" },
        { key: "Payment Related Queries", value: "Payment Related Queries" },
        { key: "Conference Related Queries", value: "Conference Related Queries" },
        { key: "Other", value: "Other" }
    ]

     //Prepare Data for API
     const prepareRequestBody = async (values) => {
        const prepareData = new ContactEnquiryModel();

        //to assing id based on action 
        if (("" + initialValue.contactEnquiryId)?.length > 0) {
            prepareData.contactEnquiryId = initialValue.contactEnquiryId;
        } else {
            prepareData.contactEnquiryId = values.contactEnquiryId ? values.contactEnquiryId : '';
        }

        prepareData.name = values.name ? values.name : '';
        prepareData.email = values.email ? values.email : '';
        prepareData.phone = values.phone ? values.phone : '';
        prepareData.category = values.category ? values.category : '';
        prepareData.message = values.message ? values.message : '';
        
        

        return prepareData;

    }

    const onSubmit = async (values) => {
        const requestData = await prepareRequestBody(values);
        const res = await ContactEnquiryService.saveContactEnquiry(requestData);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            return true
        } else {
            return false
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={ContactEnquirySchema}
            onSubmit={async (values, { resetForm }) => {
                const flag = await onSubmit(values);
                if (flag) {
                    resetForm();
                }
            }}
        >
            {
                ({ isValid, dirty, isSubmitting, values }) => (
                    <div>
                        <Form>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    {/* <input type="text" placeholder="Name" /> */}
                                    <FormikControl control='textfield'
                                                                    name='name'
                                                                    placeholder="Name"
                                                                    className="input-form-data"
                                                                />
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/* <input type="text" placeholder="Email" /> */}
                                    <FormikControl control='textfield'
                                                                    name='email'
                                                                    placeholder="Email"
                                                                    className="input-form-data"
                                                                />
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/* <input type="text" placeholder="Phone" /> */}
                                    <FormikControl control='textfield'
                                                                    name='phone'
                                                                    placeholder="Phone"
                                                                    className="input-form-data"
                                                                />
                                </div>
                                <div className="col-md-6 mb-3">
                                     <FormikControl
                                                                    control='autocomplete'
                                                                    name="category"
                                                                    placeholder="Category"
                                                                    options={categoryList}
                                                                    keyfield="key"
                                                                    valuefield="value"
                                                                    className="input-form-data"

                                                                />
                                </div>
                                <div className="col-md-12 mb-3">
                                    {/* <textarea name="#" placeholder="Your Massage"></textarea> */}
                                    <FormikControl control='textfield'
                                                                    name='message'
                                                                    placeholder="Message"
                                                                    className="input-form-data"
                                                                />
                                </div>
                                <div className="col-md-12">
                                    <div className="faq-one__btn-box contact-btn">
                                        <button type="submit" className="thm-btn faq-one__btn" disabled={!dirty || !isValid}>Submit
                                            <span></span></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }
        </Formik>





    )
}

export default ContactEnquiry
